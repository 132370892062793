// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/providers/authProvider.client.ts"
);
import.meta.hot.lastModified = "1710414077964.0054";
}
// REMIX HMR END

import type { AuthBindings } from '@refinedev/core';
import { callAuthProvider } from '~/services/callAuthProvider.client';
import axios from 'axios';
import { CheckResponse } from '@refinedev/core/dist/interfaces';

interface AuthResponse {
  success: boolean;
  message: string;
}

interface AuthProviderRequest {
  methodName: keyof typeof authProvider
  parameters: any
}

export const authProvider: AuthBindings = {
  login: async ({ email, password }) => {
    try {
      const response = await axios.post('/action/login', new URLSearchParams({
        email,
        password,
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      return response.data;
    } catch (error) {
      return {
        success: false,
        error: {
          message: 'Login failed',
          name: 'An error occurred while executing the request',
        },
      }
    }
  },
  logout: async () => {
    return {
      success: true,
      redirectTo: '/logout',
    }
  },
  onError: async (error) => {
    console.error(error)
    return { error }
  },
  check: async (request): Promise<CheckResponse> => {
    const result = await callAuthProvider<CheckResponse>('check', request);
    if (!result.error && result.data) {
      return result.data;
    }
    
    return { authenticated: false };
  },
  getPermissions: async (request) => {
    const result = await callAuthProvider<AuthProviderRequest>('getPermissions', request);
    return !result.error ? result.data : [];
  },
  getIdentity: async (request) => {
    const result = await callAuthProvider<AuthProviderRequest>('getIdentity', request);
    if (!result.error && result.data) {
      return result.data;
    }
    
    return null;
  },
}
