// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/services/accessControl.ts"
);
import.meta.hot.lastModified = "1712567202196.7527";
}
// REMIX HMR END

import { CanParams } from '@refinedev/core'
import { authProvider } from '~/providers/authProvider.client'
import camelCase from 'camelcase'

const CACHE_LIFETIME = 2000

let permissionsData: { data: Promise<string[]>; timestamp: number } | null = null
let loadingPromise: Promise<string[]> | null = null

const getPermissionsData = async (): Promise<string[]> => {
  const now = Date.now()

  if (permissionsData !== null && now - permissionsData.timestamp < CACHE_LIFETIME) {
    return permissionsData.data
  } else if (loadingPromise) {
    return loadingPromise
  } else {
    loadingPromise = (async () => {
      const loadedPermissions = await (authProvider.getPermissions
        ? (authProvider.getPermissions() as Promise<string[]>)
        : Promise.resolve([]))
      if (loadedPermissions.length > 0) {
        permissionsData = {
          data: Promise.resolve(loadedPermissions),
          timestamp: now,
        }
      }
      loadingPromise = null
      return loadedPermissions
    })()

    return loadingPromise
  }
}

export const hasAccess = async ({ resource, action, params }: CanParams): Promise<boolean> => {
  const permission = await getPermissionsData()

  if (!permission.length) {
    return false
  }

  if (resource === 'systemRole' && action === 'edit' && (params?.code === 'root' || params?.code === 'guest')) {
    return false
  }

  if (!resource) {
    return permission.includes('ALL')
  }

  let checks = getChecks(resource, action)

  if (permission.includes('ALL') || checks.some((check) => permission.includes(check))) {
    return true
  }

  return false
}

const getChecks = (resource: string, action: string): string[] => {
  if (['My Account', 'my account'].includes(resource)) {
    return ['auth']
  }

  if (action === 'list') {
    return [
      camelCase(resource || '') + camelCase(action, { pascalCase: true }),
      camelCase(convertResource(resource) || '') + camelCase(action, { pascalCase: true }),
    ]
  } else {
    return [
      camelCase(action) + camelCase(resource || '', { pascalCase: true }),
      camelCase(action) + camelCase(convertResource(resource) || '', { pascalCase: true }),
    ]
  }
}

const convertResource = (resourceName: string): string => {
  if (resourceName === 'currencies') return 'currency'
  if (resourceName === 'currency') return 'currencies'
  if (resourceName.endsWith('s')) {
    return resourceName.slice(0, -1)
  } else {
    return resourceName + 's'
  }
}
