// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/contexts/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/contexts/index.tsx");
  import.meta.hot.lastModified = "1705591115892.961";
}
// REMIX HMR END

import { RefineThemes } from "@refinedev/antd";
import { ConfigProvider, theme } from "antd";
import React from "react";
export const ColorModeContext = React.createContext({});
export const ColorModeContextProvider = ({
  children
}) => {
  _s();
  const [mode, setMode] = React.useState("light");
  const setColorMode = () => {
    if (mode === "light") {
      setMode("dark");
    } else {
      setMode("light");
    }
  };
  const {
    darkAlgorithm,
    defaultAlgorithm
  } = theme;
  return <ColorModeContext.Provider value={{
    setMode: setColorMode,
    mode
  }}>
      <ConfigProvider
    // you can change the theme colors here. example: ...RefineThemes.Magenta,
    theme={{
      ...RefineThemes.Blue,
      algorithm: mode === "light" ? defaultAlgorithm : darkAlgorithm
    }}>
        {children}
      </ConfigProvider>
    </ColorModeContext.Provider>;
};
_s(ColorModeContextProvider, "ckwlr2Ue1/16M01etSJDlsATeMQ=");
_c = ColorModeContextProvider;
var _c;
$RefreshReg$(_c, "ColorModeContextProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;