// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/services/callAuthProvider.client.ts"
);
import.meta.hot.lastModified = "1710875080978.9905";
}
// REMIX HMR END

import axios from 'axios'
import { ApiResponse } from '~/interfaces/api'

export const callAuthProvider = async <TData>(
  methodName: string,
  parameters: any,
): Promise<ApiResponse<TData>> => {
  try {
    const response = await axios.post('/action/auth', {
      methodName,
      parameters,
    })

    return response.data
  } catch (error) {
    console.error(error)
    return { error: 'An error occurred while executing the request' }
  }
}
